.one-project-container {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-flow: dense;
  position: relative;
}

.project-gif {
  width: 37.5rem;
  height: 22rem;
  grid-column: 1/3;
}

.project-gif,
.project-text-container {
  grid-row: 1/2;
}

.project-text-container {
  width: 25rem;
  height: 22rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-column: 2/-1;
  margin-top: 1rem;
}

.project-name {
  font-size: 1.8rem;
  font-weight: 500;
}

.project-description {
  background-color: var(--project-background-color);
  z-index: 5;
  padding: 1rem 1.5rem;
  font-size: 0.8rem;
}

.used-list,
.project-links {
  display: flex;
  list-style: none;
  gap: 20px;
  padding: 0;
  direction: ltr;
}

.project-icon {
  fill: var(--secondary-color);
  cursor: pointer;
  transform: translateY(0);
  transition: ease 0.4s;
}

.project-icon:hover {
  transform: translateY(-3px);
  transition: ease 0.4s;
}

.used {
  font-size: 0.9rem;
  color: #b8b8b8;
}

.ul-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.video-filter {
  position: absolute;
  top: 0.5rem;
  width: 37.5rem;
  height: 21.1rem;
  background-color: #b8b8b8;
  opacity: 0.3;
  transition: opacity 1s ease;
}

@keyframes opacityAnimation {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes opacityAnimationBackwards {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@media screen and (max-width: 1024px) {
  .one-project-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .project-gif {
    width: 100%;
    object-fit: fill;
    height: 17rem;
  }
  .project-text-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .ul-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .video-filter {
    width: 100%;
    height: 17rem;
    top: 0;
    opacity: 0.3;
  }
}
