.left-sidebar-container {
  z-index: 3;
  position: fixed;
  height: 250px;
  background-color: transparent;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10rem;
}

.ls-logo-container {
  position: relative;
  width: 40px;
  height: 45px;
  cursor: pointer;
}

.ls-logo-container .ls-letter {
  position: absolute;
  transition: all 0.4s linear;
}

.ls-letter {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 1rem;
}

.ls-pos1 {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 3px;
  margin-right: 6px;
}

.ls-pos2 {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 3px;
  margin-left: 6px;
}

.ls-pos3 {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 3px;
  margin-right: 6px;
}

.ls-pos4 {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 3px;
  margin-left: 6px;
}

.vertical-line {
  width: 2px;
  height: 60px;
  background-color: var(--primary-color);
}

.left-sections-list {
  list-style: none;
  padding: 0;
}

.llink {
  margin-bottom: 1rem;
  cursor: pointer;
  transform: translateY(0);
  transition: ease 0.4s;
}

.llink:hover {
  transform: translateY(-3px);
  transition: ease 0.4s;
}

.left-svg {
  fill: var(--primary-color);
}
