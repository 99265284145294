:root {
  --primary-color: #f3f3f3;
  --secondary-color: #333;
  --button-hover: #c9c9c9;
  --project-background-color: #3d3d3d;
  --project-button-hover: #3a3a3a;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background: url(./imgs/noise-effect.png);
  background-color: var(--secondary-color);
  color: var(--primary-color);
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  html {
    width: 100%;
  }
  body {
    width: 100%;
  }
}
