.techstack-section {
  margin: 5rem 0;
}

.right-line,
.left-line {
  width: 10rem;
}

.techstack-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
}

.tech-header {
  font-size: 2.3rem;
}

.slider {
  height: 90px;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
  position: relative;
  background: url(../imgs/noise-effect.png);
}

.slider::before,
.slider::after {
  position: absolute;
  background-image: linear-gradient(to right, var(--project-button-hover) 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100%;
  width: 25%;
  z-index: 2;
  pointer-events: none;
}

.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slide-track {
  width: calc(75px * 18);
  display: flex;
  animation: scroll 20s linear infinite;
  justify-content: space-between;
}

.play-anim {
  animation-play-state: running;
}

.pause-anim {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc((-75px + (-3.5rem) + (-3.5rem)) * 9));
  }
}

@media screen and (max-width: 768px) {
  .slide-track {
    width: calc(50px * 18);
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc((-50px + (-3.5rem) + (-3.5rem)) * 9));
    }
  }
}
