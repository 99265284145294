.about-section {
  margin-left: 13rem;
  margin-top: 10rem;
}

.about-container {
  display: flex;
}

.about-header-container {
  display: flex;
  align-items: center;
}

.right-line {
  width: 9rem;
  background-color: var(--primary-color);
  height: 2px;
  margin-left: 1rem;
}

.about-header {
  font-size: 2.3rem;
}

.about-text-container {
  width: 50%;
  margin-top: 3rem;
}

.frame-container {
  margin-left: 5rem;
  margin-top: 2rem;
  width: 20rem;
  height: 22rem;
  position: relative;
}

.photo-container {
  width: 20rem;
  height: 22rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 18rem;
  height: 20rem;
}

.frame-top {
  height: 2px;
  width: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--primary-color);
}

.frame-left {
  height: 3rem;
  width: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--primary-color);
}

.frame-right {
  background-color: var(--primary-color);
  height: 3rem;
  width: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.frame-bottom {
  background-color: var(--primary-color);
  height: 2px;
  width: 3rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.about-text {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .about-section {
    padding: 1rem;
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .about-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .about-text-container {
    width: 100%;
  }
  .frame-container {
    margin: 0;
  }
  .ph-pos-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  p {
    word-break: break-word;
  }
}
